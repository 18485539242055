import React from 'react'
import Select from 'react-select'
interface SelectHistoryProps {
  values: string[]
  option: number
  onSelect: (value: number) => void
}

export default function SelectHistory({
  values,
  option,
  onSelect,
}: SelectHistoryProps): React.ReactElement {
  const optionsAmm = values.map((item: string, index: number) => {
    return {
      value: index,
      label: item,
    }
  })

  const clearStyles = new Proxy(
    {},
    {
      get: () => (): void => {
        //
      },
    }
  )

  return (
    <Select
      options={optionsAmm}
      className="Select Select--sm Select--light"
      classNamePrefix="Select"
      onChange={(value): void => {
        onSelect(value ? value.value : 0)
      }}
      defaultValue={optionsAmm[option]}
      isMulti={false}
      isSearchable={false}
      closeMenuOnScroll={false}
      styles={clearStyles}
    />
  )
}
